import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useGetAddress, useStoreAddress } from "hooks/addresses";
import { useGetCountries, useGetCountriesShort } from "hooks/countries";
import { useEffect, useRef } from "react";
import { Button, Input, PhoneWithPrefix, SelectComponent } from "shared";
import * as Yup from "yup";

interface CompanyAddressProps {
  onClose: () => void;
}

const CompanyAddress: React.FC<CompanyAddressProps> = ({ onClose }) => {
  const { data: countries } = useGetCountries();
  const { data: countriesShort } = useGetCountriesShort();
  const { mutateAsync: storeAddress } = useStoreAddress();
  const { data } = useGetAddress();

  const formRef = useRef<any>(null);

  useEffect(() => {
    if (countries && formRef.current && !data?.countryUuid) {
      const poland = countries.find((country) => country.label === "Polska");
      formRef.current.setFieldValue(
        "countryUuid",
        poland?.value || countries[0].value
      );
    }
  }, [countries, data]);

  useEffect(() => {
    if (countriesShort && formRef.current && !data?.nipPrefix) {
      const poland = countriesShort.find((country) => country.label === "PL");
      formRef.current.setFieldValue(
        "nipPrefix",
        poland?.value || countriesShort[0].value
      );
    }
  }, [countriesShort, data]);


  const initialValues = {
    companyName: data?.companyName || "",
    nipPrefix: data?.nipPrefix || "",
    nip: data?.nip || "",
    address: data?.address || "",
    postalCode: data?.postalCode || "",
    city: data?.city || "",
    countryUuid: data?.countryUuid || "",
  };

  const onSubmit = async (values: typeof initialValues) => {
    storeAddress({ ...values, type: "company" }, { onSuccess: onClose });
  };
  const validation = () =>
    Yup.object().shape({
      address: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      postalCode: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      city: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      countryUuid: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      nip: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      nipPrefix: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={validation()}
      enableReinitialize
      innerRef={formRef}
    >
      {({ errors, values, handleSubmit }) => (
        <Form className="m-width-100">
          <Field
            type="text"
            id="companyName"
            name="companyName"
            label={"Nazwa firmy"}
            as={Input}
          />
          <PhoneWithPrefix>
            <Field
              label="NIP"
              name="nipPrefix"
              required
              id="nipPrefix"
              as={SelectComponent}
              options={countriesShort}
            />
            <Field name="nip" type="text" id="nip" maxLength="12" as={Input} />
          </PhoneWithPrefix>
          <Field
            type="text"
            id="address"
            name="address"
            label={"Adres"}
            as={Input}
          />

          <Field
            type="text"
            id="postalCode"
            name="postalCode"
            label={"Kod pocztowy"}
            as={Input}
          />
          <Field
            type="text"
            id="city"
            name="city"
            label={"Miasto"}
            as={Input}
          />

          <Field
            label="Kraj"
            name="countryUuid"
            required
            id="countryUuid"
            as={SelectComponent}
            options={countries}
            className="select--left"
          />

          <div className="fullscreen-modal__buttons">
            <Button
              label="Anuluj"
              onClick={onClose}
              className="button--stroke button--rounded"
            />
            <Button
              label={"Zapisz zmiany"}
              type="submit"
              onClick={() => null}
              className=" button--rounded button--black"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default CompanyAddress;
