import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { OptionsType } from "types/UtilsTypes";

export default function useGetClientsOptions() {
  return useQuery({
    queryKey: ["users/clients"],

    queryFn: async () => {
      return axiosInstance.get(`users/clients`).then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: OptionsType[]) => data,
  });
}
