/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Table from "rc-table";
import { mobileColumns, desktopColumns } from "./utils";
import { Pagination } from "shared";
import { isDesktop } from "utils";
// import { data } from "utils/userBase";
import "./companies-table.scss";
import { useSearchParams } from "react-router-dom";
import { useGetCompanies } from "hooks/companies";
import { useGetIndustriesHook } from "hooks/industries";
import { CompanyStatusType, CompanyType } from "types/CompanyTypes";
import { Tooltip } from "react-tooltip";
interface CompaniesTableType {
  page: number;
  setPage: (page: number) => void;
  tab: "relaince" | "other" | "my";
  onRowClick?: (
    record: CompanyType,
    e: React.MouseEvent<any, MouseEvent>
  ) => void;
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
}

const CompaniesTable = ({
  setPage,
  page,
  tab,
  onRowClick,
  setEditCompany,
  handleStatusChange,
}: CompaniesTableType) => {
  const { data: industries } = useGetIndustriesHook();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, refetch } = useGetCompanies(page);
  useEffect(() => {
    refetch();
  }, [page, tab]);

  const handleChangePage = (e: number) => {
    setPage(e);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", e.toString());
    newSearchParams.set("role", tab);
    setSearchParams(newSearchParams);
  };

  return (
    <div className="companies-table">
      <Tooltip
        place="top"
        className="tooltip tooltip--noShadow"
        id="company-tooltip"
      />
      <Table
        rowKey="id"
        className="companies-table"
        data={data?.data}
        onRow={(record: any) => ({
          onClick: (e) => onRowClick && onRowClick(record, e),
        })}
        columns={
          isDesktop()
            ? desktopColumns({ industries, setEditCompany, handleStatusChange })
            : mobileColumns()
        }
      />
      {data?.meta && data?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={data?.meta?.lastPage}
          currentPage={data?.meta?.currentPage || page}
          onPageChange={(e: any) => handleChangePage(e)}
        />
      )}
    </div>
  );
};

export default CompaniesTable;
