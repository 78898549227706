import { useState } from "react";
import { FullscreenModal, LargeSwitch } from "shared";
import { UserType } from "types/UsersTypes";
import { CompanyAddress, PersonalAddress } from "./Components";

interface EditAddressDataModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  user: UserType | null;
}

const EditAddressDataModal: React.FC<EditAddressDataModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [tab, setTab] = useState<"personal" | "business">("personal");

  const switchOptions = [
    {
      label: "Osobowe",
      value: "personal",
    },
    {
      label: "Firmowe",
      value: "business",
    },
  ];

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">Edycja danych adresowych</h2>
      <LargeSwitch
        state={tab}
        onChange={(value) => setTab(value)}
        options={switchOptions}
        className="large-switch--bottom"
      />
      {tab === "personal" ? <PersonalAddress onClose={onClose} /> : null}
      {tab === "business" ? <CompanyAddress onClose={onClose} /> : null}
    </FullscreenModal>
  );
};

export default EditAddressDataModal;
