import { UserStatus, ContextMenu, SVG, CompanyStatus } from "shared";
import { SVG_ENUM } from "enums";
import { UserType } from "types/UsersTypes";
import { CompanyStatusType, CompanyType } from "types/CompanyTypes";
import moment from "moment";
import { IndustryOptionsType } from "types/IndustryTypes";

const items = ({
  setEditCompany,
  handleStatusChange,
}: {
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
}) => {
  return (company: CompanyType) => {
    const actions = [];

    if (company.status !== "withdrawn") {
      actions.push({
        name: "Edytuj",
        action: () => setEditCompany(company),
        svg: SVG_ENUM.EDIT,
      });

      if (company.status !== "hidden") {
        actions.push({
          name: "Ukryj",
          action: () => handleStatusChange(company, "hidden"),
          svg: SVG_ENUM.CROSSED_EYE,
        });
      } else {
        actions.push({
          name: "Pokaż",
          action: () => handleStatusChange(company, "active"),
          svg: SVG_ENUM.EYE,
        });
      }
      actions.push({
        name: "Wycofaj",
        action: () => handleStatusChange(company, "withdrawn"),
        svg: SVG_ENUM.STOP_SIGN,
      });
    }

    if (company.status === "withdrawn") {
      actions.push({
        name: "Przywróć",
        action: () => handleStatusChange(company, "active"),
        svg: SVG_ENUM.ARROW_UNDO,
      });
    }

    // actions.push({
    //   name: user.status === "suspended" ? "Przywróć" : "Zawieś",
    //   action: () => blockUser(user),
    //   svg:
    //     user.status === "suspended" ? SVG_ENUM.ARROW_UNDO : SVG_ENUM.LOCK,
    // });

    // } else {
    //   actions.push({
    //     name: "Zmień hasło",
    //     action: () => changePassword(),
    //     svg: SVG_ENUM.LOCK,
    //   });
    // }
    return actions;
  };
};

const userItems = () => {
  return (user: UserType) => {
    const actions = [];
    if (user.status !== "inactive") {
      actions.push({
        name: user.status === "suspended" ? "Przywróć" : "Zawieś",
        action: () => console.log(user),
        svg: user.status === "suspended" ? SVG_ENUM.ARROW_UNDO : SVG_ENUM.LOCK,
      });
    }
    return actions;
  };
};

export const mobileColumns = () => [
  {
    width: "calc(50% - 10px)",
    render: (values: any) => (
      <div className="users-table-mobile__name">{`${values.name}`}</div>
    ),
  },
  {
    dataIndex: "status",
    key: "status",
    width: 100,
    render: (value: string) => <UserStatus status={value} />,
  },
  {
    width: 88,
    render: (item: UserType) => (
      <ContextMenu data={item} items={userItems()(item)}>
        <SVG type={SVG_ENUM.DOTS} />
      </ContextMenu>
    ),
  },
];

export const desktopColumns = ({
  industries,
  setEditCompany,
  handleStatusChange,
}: {
  industries: IndustryOptionsType[] | undefined;
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
}) => [
  {
    title: "Nazwa spółki",
    key: "index",
    width: 40,
    render: (value: any, record: CompanyType, index: number) => (
      <div
        data-tooltip-id="company-tooltip"
        data-tooltip-content={record.description}
        className="companies-table__info"
      >
        <SVG type={SVG_ENUM.INFO} />
      </div>
    ),
  },
  {
    dataIndex: "name",
    key: "name",
    width: 120,
  },
  {
    title: "Branża",
    width: 100,
    render: (value: any, record: CompanyType, index: number) => {
      const industry = industries?.find(
        (industry) => industry.value === record.industryUuid
      );
      return industry?.label;
    },
  },
  {
    title: "Max wartość akcji",
    width: 130,
    render: (value: any, record: CompanyType, index: number) =>
      `${record.maxShareValues} ${record.currency}`,
  },
  {
    title: "Dostępna wartość akcji",
    width: 150,
    render: (value: any, record: CompanyType, index: number) =>
      `${record.availableShares} ${record.currency}`,
  },
  {
    title: "Waluta",
    width: 60,
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Wycena spółki",
    width: 120,
    render: (value: any, record: CompanyType, index: number) =>
      `${record.valuation} ${record.currency}`,
  },
  {
    title: "Data publikacji na giełdzie",
    width: 170,
    render: (value: any, record: CompanyType, index: number) =>
      `${moment(record.publicationDate, "DD/MM/YYYY").format("DD/MM/YYYY")}r.`,
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 120,
    render: (value: CompanyStatusType["status"]) => (
      <CompanyStatus status={value} />
    ),
  },
  {
    width: 88,
    render: (item: CompanyType) => (
      <ContextMenu
        data={item}
        items={items({ setEditCompany, handleStatusChange })(item)}
      >
        <SVG type={SVG_ENUM.DOTS} />
      </ContextMenu>
    ),
  },
];
