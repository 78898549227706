import { Route, Routes } from "react-router-dom";
import { ROUTE_ENUM } from "enums";
import {
  RegisterPage,
  LoginPage,
  EnterCodePage,
  RegisterAdminPage,
  LoginAdminPage,
  LinkExpiredPage,
  ResetPasswordPage,
  CreateNewPasswordPage,
  UsersPage,
  ProfilePage,
  CompaniesPage,
  SvgTestPage,
} from "screens";
import { Suspense } from "react";
import { ProtectedRoute } from "utils";
import { PacketModal } from "features/users";

const RoutesComponent: React.FC = () => {
  return (
    <Suspense fallback={"..."}>
      <Routes>
        <Route
          path={ROUTE_ENUM.HOME}
          element={
            <PacketModal isOpen={true} onClose={() => ({})} parent="homepage" />
          }
        />
        <Route path={ROUTE_ENUM.REGISTER} element={<RegisterPage />} />
        <Route
          path={ROUTE_ENUM.REGISTER_ADMIN}
          element={<RegisterAdminPage />}
        />
        <Route path={ROUTE_ENUM.LOGIN} element={<LoginPage />} />
        <Route path={ROUTE_ENUM.LOGIN_ADMIN} element={<LoginAdminPage />} />
        <Route path={ROUTE_ENUM.LINK_EXPIRED} element={<LinkExpiredPage />} />
        <Route path={ROUTE_ENUM.ENTER_CODE} element={<EnterCodePage />} />
        <Route path={`${ROUTE_ENUM.DASHBOARD}/*`} element={<ProtectedRoute />}>
          <Route
            path={ROUTE_ENUM.PROFILE.replace(`${ROUTE_ENUM.DASHBOARD}/`, "")}
            element={<ProfilePage />}
          />
          <Route
            path={ROUTE_ENUM.COMPANIES.replace(`${ROUTE_ENUM.DASHBOARD}/`, "")}
            element={<CompaniesPage />}
          />
        </Route>
        <Route
          path={`${ROUTE_ENUM.DASHBOARD}/*`}
          element={<ProtectedRoute isAdminOnly />}
        >
          <Route
            path={ROUTE_ENUM.USERS.replace(`${ROUTE_ENUM.DASHBOARD}/`, "")}
            element={<UsersPage />}
          />
        </Route>
        <Route
          path={ROUTE_ENUM.RESET_PASSWORD}
          element={<ResetPasswordPage />}
        />
        <Route path={ROUTE_ENUM.SVG_TEST} element={<SvgTestPage />} />
        <Route
          path={ROUTE_ENUM.CREATE_NEW_PASSWORD}
          element={<CreateNewPasswordPage />}
        />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponent;
