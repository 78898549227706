import { useState } from "react";
import {
  Button,
  PasswordStrengthChecker,
  PasswordValidation,
  Input,
  FullscreenModal,
} from "shared";
import "./change-password.scss";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useChangePassword } from "hooks/auth";
import { VALIDATION_ENUM } from "enums";

interface ChangePasswordType {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePassword = ({ isOpen, onClose }: ChangePasswordType) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const resetPassword = useChangePassword({
    onSuccess: () => {
      onClose();
    },
  });

  const handleChangePassword = async ({
    currentPassword,
    newPassword,
  }: any) => {
    resetPassword.mutate({ currentPassword, newPassword });
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, VALIDATION_ENUM.MIN_8)
      .required(VALIDATION_ENUM.REQUIRED),
    newPassword: Yup.string()
      .min(8, VALIDATION_ENUM.MIN_8)
      .required(VALIDATION_ENUM.REQUIRED),
    confirm_password: Yup.string()
      .min(8, VALIDATION_ENUM.MIN_8)
      .required(VALIDATION_ENUM.REQUIRED)
      .oneOf([Yup.ref("newPassword"), ""], VALIDATION_ENUM.CONFIRM_PASSWORD),
  });

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirm_password: "",
  };

  return (
    <>
      <FullscreenModal isOpen={isOpen} onClose={() => onClose()}>
        <>
          <h2 className="fullscreen-modal__header">Zmiana hasła</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleChangePassword(values)}
          >
            {({ handleSubmit, errors, isSubmitting, values }) => (
              <Form className="m-width-100">
                <Field
                  type="password"
                  label="Aktualne hasło"
                  name="currentPassword"
                  id="currentPassword"
                  as={Input}
                  // errors={errors}
                  onBlur={() => isPasswordValid && setShowValidation(false)}
                />
                <Field
                  type="password"
                  label="Nowe hasło"
                  name="newPassword"
                  id="newPassword"
                  as={Input}
                  errors={errors}
                  onFocus={() => setShowValidation(true)}
                  onBlur={() => isPasswordValid && setShowValidation(false)}
                />
                <PasswordStrengthChecker
                  isHidden={!showValidation}
                  password={values.newPassword}
                />
                <PasswordValidation
                  show={showValidation}
                  // className={` ${
                  //   showValidation ? "validation-item__wrapper--show" : ""
                  // }`}
                  password={values.newPassword}
                  setIsValid={(value: boolean) => setIsPasswordValid(value)}
                />
                <Field
                  type="password"
                  label="Powtórz nowe hasło"
                  name="confirm_password"
                  id="confirm_password"
                  as={Input}
                  errorBelow
                  errors={errors}
                  wrapperClassName="white form__field--gray"
                />
                <div className="modal-buttons">
                  <Button
                    className="button--rounded button--stroke"
                    onClick={() => onClose()}
                    label="Anuluj"
                  />
                  <Button
                    className=" button--rounded button--black"
                    onClick={() => {}}
                    label="Potwierdź"
                    type="submit"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </>
      </FullscreenModal>
    </>
  );
};

export default ChangePassword;
