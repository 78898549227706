import { VALIDATION_ENUM } from "enums";
import React from "react";
import "./PaymentMethodStep.scss";
import { Button, Checkbox, Input } from "shared";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import moment from "moment";

interface Props {
  goToNextStep: () => void;
}

const PaymentMethodStep: React.FC<Props> = ({ goToNextStep }) => {
  const initialValues = {
    creditCardNumber: "",
    creditCardDate: "",
    CCV: "",
    acceptance: false,
  };

  const validation = () =>
    Yup.object().shape({
      creditCardNumber: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      creditCardDate: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      CCV: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      acceptance: Yup.boolean().oneOf([true], VALIDATION_ENUM.REQUIRED),
    });

  const onSubmit = (values: any) => {
    console.log(values);
    goToNextStep();
  };

  return (
    <>
      <h2 className="fullscreen-modal__header packet-modal__header">
        Metoda płatności
      </h2>
      <div className="payment-method-step">
        <p className="login-wrapper__subtitle">
          Podłącz swoją kartę płatniczą Lorem ipsum dolor sit amet consectetur.
          Feugiat
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values)}
          validationSchema={validation()}
        >
          {({ errors, values, handleSubmit }) => (
            <Form>
              <Field
                type="text"
                id="creditCardNumber"
                name="creditCardNumber"
                label={"Numer karty"}
                as={Input}
              />
              <div className="payment-method-step__wrap">
                <Field
                  type="text"
                  id="creditCardDate"
                  name="creditCardDate"
                  label={"Data ważności"}
                  as={Input}
                />
                <Field
                  type="text"
                  id="CCV"
                  name="CCV"
                  label={"CCV"}
                  as={Input}
                />
              </div>
              <Field
                id="acceptance"
                name="acceptance"
                wrapperClassName="login-wrapper__acceptance"
                label={
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Feugiat at ultrices
                    mattis tortor. Lorem ipsum dolor sit amet consectetur.
                  </p>
                }
                as={Checkbox}
              />
              <p className="payment-method-step__date">
                Możesz zrezygnować z subskrybcji w dowolnym momenciem nawet
                przed końcem okresu próbnego. <br />
                <br />
                Pierwsza opłata zostanie pobrana{" "}
                <b>{moment().add(1, "month").format("DD.MM.YYYY")}</b>
              </p>
              <div className="fullscreen-modal__buttons">
                <Button
                  label="Dalej"
                  className="button--black button--rounded"
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PaymentMethodStep;
