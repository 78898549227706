/* eslint-disable react-hooks/exhaustive-deps */
import Table from "rc-table";
import { mobileColumns, desktopColumns } from "./utils";
import { isDesktop } from "utils";
// import { data } from "utils/userBase";
import { CompanyType } from "types/CompanyTypes";
import { Tooltip } from "react-tooltip";
import { useGetSharesByCompany } from "hooks/shares";
import "./ClientsTable.scss";

interface ClientsTableType {
  company: CompanyType | null;
}

const ClientsTable = ({ company }: ClientsTableType) => {
  const { data } = useGetSharesByCompany(company?.uuid ? company.uuid : "");
  return (
    <div className="companies-table clients-table">
      <Tooltip
        place="top"
        className="tooltip tooltip--noShadow"
        id="company-tooltip"
      />
      <Table
        rowKey="id"
        className="companies-table"
        data={data}
        columns={isDesktop() ? desktopColumns() : mobileColumns()}
      />
    </div>
  );
};

export default ClientsTable;
