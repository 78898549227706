import { PACKET_ENUM, VALIDATION_ENUM } from "enums";
import React, { useState } from "react";
import "./ChoosePacketStep.scss";
import SinglePacket from "./SinglePacket";
import { Button, Input } from "shared";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ParentType } from "../../PacketModal";
import { useChangePacket } from "hooks/users";

interface Props {
  onClose: () => void;
  parent: ParentType;
  goNextStep: () => void;
  setStepOptions?: any;
  stepOptions?: any;
}

const ChoosePacketStep: React.FC<Props> = ({
  onClose,
  parent,
  goNextStep,
  setStepOptions,
  stepOptions,
}) => {
  const [packet, setPacket] = useState<PACKET_ENUM>(
    stepOptions?.ChoosePacketStep?.data || null
  );
  const [selectedPacket, setSelectedPacket] = useState<PACKET_ENUM | null>(
    null
  );

  const { mutate: changePacket } = useChangePacket();

  const handlePacketChange = (packet: PACKET_ENUM) => {
    if (setStepOptions)
      setStepOptions({ ...stepOptions, ChoosePacketStep: { data: packet } });
    setPacket(packet);
    if (parent === "homepage") goNextStep();
    if (parent === "payment") {
      changePacket(packet, { onSuccess: () => onClose() });
    }
  };

  const isFromPayment = parent === "payment";
  const isFromHomepage = parent === "homepage";

  const validationSchema = Yup.object().shape({
    coupon: Yup.string().required(VALIDATION_ENUM.REQUIRED),
  });

  const handleCupponChange = (values: any) => {
    console.log(values);
  };

  return (
    <>
      <h2 className="fullscreen-modal__header packet-modal__header">
        Wybierz pakiet, który Cię interesuje
      </h2>
      <div className="choose-packet-step__wrapper">
        {isFromHomepage ? (
          <Formik
            initialValues={{ coupon: "" }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleCupponChange(values)}
          >
            {({ handleSubmit, errors, isSubmitting, values }) => (
              <Form className="choose-packet-step__coupon">
                <Field
                  type="text"
                  id="coupon"
                  name="coupon"
                  label={
                    <p className="choose-packet-step__coupon-label">
                      Masz kod rabatowy? <b>Wpisz go tutaj ↓</b>
                    </p>
                  }
                  as={Input}
                />
                <Button
                  className="button--rounded button--black button--stroke choose-packet-step__button"
                  label="Zastosuj"
                  onClick={handleSubmit}
                />
              </Form>
            )}
          </Formik>
        ) : null}
        <div className="choose-packet-step">
          <SinglePacket
            packetName={PACKET_ENUM.BASIC}
            currentPacket={packet}
            selectedPacket={selectedPacket}
            setSelectedPacket={setSelectedPacket}
            parent={parent}
          />
          <SinglePacket
            packetName={PACKET_ENUM.STANDARD}
            currentPacket={packet}
            selectedPacket={selectedPacket}
            setSelectedPacket={setSelectedPacket}
            parent={parent}
          />
          <SinglePacket
            packetName={PACKET_ENUM.PREMIUM}
            currentPacket={packet}
            selectedPacket={selectedPacket}
            setSelectedPacket={setSelectedPacket}
            parent={parent}
          />
          <SinglePacket
            packetName={PACKET_ENUM.PRO}
            currentPacket={packet}
            selectedPacket={selectedPacket}
            setSelectedPacket={setSelectedPacket}
            parent={parent}
          />
        </div>
        <div
          className={`choose-packet-step__submit-buttons ${
            !!selectedPacket ? "choose-packet-step__submit-buttons--show" : ""
          }
          choose-packet-step__submit-buttons--right
          choose-packet-step__submit-buttons--${parent} 
          `}
        >
          {isFromPayment ? (
            <>
              <Button
                className="button--rounded button--black button--stroke choose-packet-step__button"
                label="Zapisz zmiany"
                onClick={() =>
                  selectedPacket && handlePacketChange(selectedPacket)
                }
              />
              <Button
                className="button--rounded button--stroke choose-packet-step__button"
                label="Anuluj"
                onClick={() => onClose()}
              />
            </>
          ) : null}
          {isFromHomepage ? (
            <Button
              className="button--rounded button--black button--stroke choose-packet-step__button"
              label="Dalej"
              onClick={() =>
                selectedPacket && handlePacketChange(selectedPacket)
              }
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ChoosePacketStep;
