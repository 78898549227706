import axiosInstance from "axiosConfig";

const downloadFile = async (path: string) => {
  return await axiosInstance
    .get(`files/${path}`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", path);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};

export default downloadFile;
