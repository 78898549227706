/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Button, DashboardTopBar } from "shared";
import { AddCompanyModal } from "features/companies";
import { CompaniesTable, CompanyInfoModal } from "features/companies";
import { useSearchParams } from "react-router-dom";
import "./users.scss";
import { CompanyStatusType, CompanyType } from "types/CompanyTypes";
import { useUpdateCompany } from "hooks/companies";
import { AddSharesModal } from "features/shares";
import { useGetMe } from "hooks/auth";
import { ROLES_ENUM } from "enums";

interface CompaniesTabsType {
  tab: "relaince" | "other" | "my";
}

const CompaniesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const linkPage = searchParams.get("page");
  interface ModalManage {
    type: string;
    company: CompanyType | null;
  }

  const [modalManage, setModalManage] = useState<ModalManage>({
    type: "",
    company: null,
  });
  const [page, setPage] = useState(Number(linkPage) || 1);
  const { data: authorizedUser } = useGetMe();
  const [tab, setTab] = useState<CompaniesTabsType["tab"]>(
    authorizedUser?.role === ROLES_ENUM.USER ? "my" : "relaince"
  );
  const { mutateAsync: updateCompany } = useUpdateCompany();

  const isAddCompanyOpenModal = modalManage.type === "addCompany";

  const clearModalManage = () => {
    setModalManage({ type: "", company: modalManage.company });
  };

  const setDetailsUser = (
    company: CompanyType,
    e: React.MouseEvent<any, MouseEvent>
  ) => {
    if (
      e.target instanceof Element &&
      e.target.closest(".context-menu__wrapper")
    ) {
      return;
    }
    setModalManage({ type: "details", company });
  };

  const handleStatusChange = async (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => {
    await updateCompany({ ...company, status });
  };

  const setAddCompany = () =>
    setModalManage({ type: "addCompany", company: null });

  const setEditCompany = (company: CompanyType) =>
    setModalManage({ type: "addCompany", company });

  const setAddClient = (company: CompanyType) =>
    setModalManage({ type: "addClient", company: company });

  const handleChangeTab = (item: CompaniesTabsType["tab"]) => {
    setTab(item);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", "1");
    newSearchParams.set("tab", item);
    setSearchParams(newSearchParams);
  };

  const isCompanyInfoOpenModal = modalManage.type === "details";

  return (
    <>
      <AddCompanyModal
        isOpen={isAddCompanyOpenModal}
        onClose={() => clearModalManage()}
        company={modalManage.company}
      />

      <CompanyInfoModal
        isOpen={isCompanyInfoOpenModal}
        onClose={() => clearModalManage()}
        company={modalManage.company}
        setAddClient={setAddClient}
      />

      <AddSharesModal
        isOpen={modalManage.type === "addClient"}
        onClose={() => clearModalManage()}
        company={modalManage.company}
      />

      <div className="companies">
        <DashboardTopBar>
          <h1 className="dashboard__header">Spółki</h1>
          <Button
            className="dashboard__top-button button--rounded"
            onClick={() => setAddCompany()}
            label="Dodaj spółkę  +"
          />
        </DashboardTopBar>
        <div className="dashboard-content__wrapper">
          <div className="dashboard-content">
            <div className="dashboard__tabs">
              {authorizedUser?.role === ROLES_ENUM.USER ? (
                <span
                  onClick={() => handleChangeTab("my")}
                  className={`dashboard__tab ${
                    tab === "my" ? "dashboard__tab--active" : ""
                  } `}
                >
                  Moje spółki
                </span>
              ) : null}
              <span
                onClick={() => handleChangeTab("relaince")}
                className={`dashboard__tab ${
                  tab === "relaince" ? "dashboard__tab--active" : ""
                } `}
              >
                Spółki Reliance
              </span>
              <span
                onClick={() => handleChangeTab("other")}
                className={`dashboard__tab ${
                  tab === "other" ? "dashboard__tab--active" : ""
                } `}
              >
                Pozostałe spółki
              </span>
            </div>
            <CompaniesTable
              page={page}
              setPage={setPage}
              tab={tab}
              onRowClick={(company: CompanyType, e: any) =>
                setDetailsUser(company, e)
              }
              setEditCompany={setEditCompany}
              handleStatusChange={handleStatusChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompaniesPage;
