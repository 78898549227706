import { SVG } from "shared";
import { SVG_ENUM } from "enums";

import { ClientType } from "types/ClientType";
import { downloadFile } from "utils";
import { SharesByCompanyType } from "types/SharesTypes";

export const mobileColumns = () => [
  {
    width: "calc(100% - 20px)",
    render: (data: ClientType) => (
      <table className="side-modal__table">
        <tr>
          <td className="side-modal__table-label">Klient:</td>
          <td className="side-modal__table-cell">{data?.name || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Wykupione udziały:</td>
          <td className="side-modal__table-cell">{data?.shares || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">
            Kwota wykupionych udziałów:
          </td>
          <td className="side-modal__table-cell">{data?.sharesValue || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Waluta:</td>
          <td className="side-modal__table-cell">{data?.currency || "-"}</td>
        </tr>
      </table>
    ),
  },

  {
    width: 88,
    render: () => <SVG type={SVG_ENUM.FILE} />,
  },
];

export const desktopColumns = () => [
  {
    title: "Klient",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "Wykupione udziały",
    width: 150,
    dataIndex: "sharesCount",
    key: "sharesCount",
  },
  {
    title: "Kwota wykupionych udziałów",
    width: 200,
    dataIndex: "sharesValue",
    key: "sharesValue",
  },
  {
    title: "Waluta",
    width: 40,
    dataIndex: "currency",
    key: "currency",
  },
  {
    width: 40,
    render: (item: SharesByCompanyType) => (
      <span className="clients-table__download" onClick={() => downloadFile(item.invoice)}>
        <SVG type={SVG_ENUM.FILE} />,
      </span>
    ),
  },
];
