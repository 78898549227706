import { SVG_ENUM } from "enums";
import React, { useState } from "react";
import { Button, SVG } from "shared";
import { UserType } from "types/UsersTypes";
import "./Payments.scss";
import { PacketModal } from "features/users";
import { useGetMe } from "hooks/auth";
import { PACKET_PRICE_ENUM } from "enums/PacketsEnum";

interface PaymentsProps {
  user: UserType | undefined;
  handleGoBack: () => void;
}

const Payments: React.FC<PaymentsProps> = ({ user, handleGoBack }) => {
  const [isPacketModalOpen, setIsPacketModalOpen] = useState(false);
  const { data: me } = useGetMe();
  return (
    <div className="profile__right-wrapper payments">
      <PacketModal
        isOpen={isPacketModalOpen}
        onClose={() => setIsPacketModalOpen(false)}
        parent="payment"
      />
      <div className="profile__right-back" onClick={() => handleGoBack()}>
        <SVG type={SVG_ENUM.ARROW_LEFT} /> Wróć
      </div>
      <h2 className="profile__menu-header">
        <span className="profile__menu-icon profile__menu-icon--right">
          <SVG type={SVG_ENUM.CREDIT_CARD} />
        </span>
        Płatności
      </h2>
      <p className="payments__label">Twój pakiet</p>
      <div className="payments__package">
        <SVG type={SVG_ENUM.LOGIN_BG_MOBILE} className="payments__package-bg" />
        <div className="payments__package-left">
          <p className="payments__package-name">Pakiet {me?.package}</p>
          <p>
            <span className="payments__package-price">
              {
                PACKET_PRICE_ENUM[
                  me?.package?.toLocaleUpperCase() as keyof typeof PACKET_PRICE_ENUM
                ]
              }
            </span>
            zł/msc
          </p>
        </div>
        <Button
          label="Zmień"
          className="button-rounded payments__package-button"
          onClick={() => setIsPacketModalOpen(true)}
        />
      </div>
    </div>
  );
};

export default Payments;
