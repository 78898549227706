import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { CompanyType } from "types/CompanyTypes";
import { MetaType } from "types/UtilsTypes";

export default function useGetCompanies(page: number) {
  return useQuery({
    queryKey: ["companies", page],
    queryFn: async () => {
      return axiosInstance
        .get(`/companies?page=${page}`)
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: { data: CompanyType[]; meta: MetaType }) => data,
    retry: false,
  });
}
