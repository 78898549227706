import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useStoreCompany, useUpdateCompany } from "hooks/companies";
import { useGetIndustriesHook } from "hooks/industries";
// import { useAddAdmin, useUpdateUser } from "hooks/users";
import {
  Button,
  CalendarInput,
  FullscreenModal,
  Input,
  SelectComponent,
  Textarea,
} from "shared";
import { CompanyStoreType, CompanyType } from "types/CompanyTypes";
import { companyStatusOpitons } from "utils";
import * as Yup from "yup";

interface AddCompanyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company?: CompanyType | null;
}

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const currentDate = new Date();
  const tommorow = currentDate.setDate(currentDate.getDate() + 1);
  const { mutateAsync: storeCompany } = useStoreCompany();
  const { mutateAsync: updateCompany } = useUpdateCompany();
  const { data: industries } = useGetIndustriesHook();
  const initialValues = {
    name: company?.name || "",
    description: company?.description || "",
    industryUuid: company?.industryUuid || "",
    maxShareValues: company?.maxShareValues || null,
    availableShares: company?.availableShares || null,
    currency: company?.currency || "",
    valuation: company?.valuation || null,
    publicationDate: company?.publicationDate || null,
    status: company?.status || null,
  };

  const onSubmit = async (values: CompanyStoreType) => {
    !!company
      ? await updateCompany(
          { ...company, ...values, uuid: company.uuid },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
          }
        )
      : await storeCompany(
          { ...values },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
          }
        );
    onClose();
  };

  const validation = () =>
    Yup.object().shape({
      name: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      description: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      industryUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      maxShareValues: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      availableShares: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      currency: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      valuation: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      publicationDate: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      status: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">
        {!!company ? "Edycja spółki" : "Dodaj spółkę"}
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100 fullscreen-modal__select-margin">
            <Field
              type="text"
              id="name"
              name="name"
              label={"Nazwa spółki"}
              as={Input}
            />

            <Field
              type="textarea"
              id="description"
              name="description"
              label={"Opis"}
              component={Textarea}
            />

            <Field
              label="Branża"
              name="industryUuid"
              id="industryUuid"
              as={SelectComponent}
              options={industries}
            />

            <Field
              type="date"
              id="publicationDate"
              name="publicationDate"
              label={"Data publikacji na giełdzie"}
              component={CalendarInput}
              maxDate={tommorow}
            />
            <Field
              type="text"
              id="currency"
              name="currency"
              label={"Waluta"}
              as={Input}
            />

            <Field
              type="number"
              id="valuation"
              name="valuation"
              label={"Wycena spółki"}
              as={Input}
            />

            <Field
              type="number"
              id="maxShareValues"
              name="maxShareValues"
              label={"Maksymalna wartość akcji/udziałów"}
              as={Input}
            />

            <Field
              type="number"
              id="availableShares"
              name="availableShares"
              label={"Dostępna wartość akcji"}
              as={Input}
            />

            <Field
              label="Status"
              name="status"
              id="status"
              as={SelectComponent}
              options={companyStatusOpitons}
            />

            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={"Potwierdź"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black"
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default AddCompanyModal;
