import { useEffect, useState } from "react";
import { SelectOption } from "types/SelectTypes";
import "./Select.scss";
import Select, { components, ActionMeta } from "react-select";
import { FieldAttributes, useField, useFormikContext } from "formik";
import SVG from "shared/SVG/SVG";
import { SVG_ENUM } from "enums";

interface SelectProps extends FieldAttributes<any> {
  label?: string;
  options?: SelectOption[];
  placeholder: string;
  name: string;
  filterOption?: any;
  onSelectChange?: (e: any) => void;
  className?: string;
  isSearchable?: boolean;
}

const CustomDropdownIndicator = (props: any) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      <SVG
        type={
          selectProps.menuIsOpen ? SVG_ENUM.CHEVRON_UP : SVG_ENUM.CHEVRON_DOWN
        }
      />
    </components.DropdownIndicator>
  );
};

const SelectComponent = ({
  label,
  options,
  name,
  filterOption,
  placeholder,
  onSelectChange,
  className = "",
  isSearchable = false,
  ...props
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );
  const formik = useFormikContext();
  const [field, meta] = useField({ ...props, name });
  useEffect(() => {
    if (field.value !== undefined) {
      const initialValue = options?.find(
        (option) => option.value === field.value
      );
      setSelectedOption(initialValue || null);
    } else {
      setSelectedOption(null);
    }
  }, [field.value, options]);

  const handleChange = (
    newValue: SelectOption | null,
    actionMeta?: ActionMeta<SelectOption>
  ) => {
    setSelectedOption(newValue);
    const valueToStore = newValue ? newValue.value : "";
    formik.setFieldValue(field.name, valueToStore);
    onSelectChange && onSelectChange(newValue);
  };

  return (
    <div className="select__wrapper">
      <div className="select__label">{label}</div>
      <Select
        {...props}
        value={selectedOption}
        name={name}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : ""}
        options={options}
        className={`select ${className}`}
        classNamePrefix="select"
        filterOption={filterOption}
        isSearchable={isSearchable}
      />
      {meta.touched && meta.error && (
        <div className="calendar-input__error">
          <span>{meta.error}</span>
        </div>
      )}
    </div>
  );
};

export default SelectComponent;
