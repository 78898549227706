import { useGetAddress } from "hooks/addresses";
import { useGetCountries } from "hooks/countries";
import React, { useEffect } from "react";
import { UserType } from "types/UsersTypes";

interface InvoiceTableProps {
  user: UserType | null;
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({ user }) => {
  const { data, refetch } = useGetAddress(user?.uuid);
  const { data: countries } = useGetCountries();
  const country = countries?.find(
    (country) => country.value === data?.countryUuid
  );

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user, refetch]);

  return (
    <table className="side-modal__table">
      <tr>
        <td className="side-modal__table-label">Nazwa firmy:</td>
        <td className="side-modal__table-cell">{data?.companyName || "-"}</td>
      </tr>
      <tr>
        <td className="side-modal__table-label">NIP:</td>
        <td className="side-modal__table-cell">{data?.nip || "-"}</td>
      </tr>
      <tr>
        <td className="side-modal__table-label">Kod pocztowy:</td>
        <td className="side-modal__table-cell">{data?.postalCode || "-"}</td>
      </tr>
      <tr>
        <td className="side-modal__table-label">Miasto:</td>
        <td className="side-modal__table-cell">{data?.city || "-"}</td>
      </tr>
      <tr>
        <td className="side-modal__table-label">Kraj:</td>
        <td className="side-modal__table-cell">{country?.label || "-"}</td>
      </tr>
    </table>
  );
};

export default InvoiceTable;
