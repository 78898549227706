import { queryClient } from "App";
import axiosInstance from "axiosConfig";
import { TOAST_TYPES_ENUM } from "enums";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { Toast } from "shared";
import { LoginSmsTypes } from "types/AuthTypes";
import { toast } from "react-toastify";

export const useLoginSms = (
  options?: UseMutationOptions<any, Error, LoginSmsTypes>
) => {
  return useMutation({
    mutationKey: ["loginSms"],
    mutationFn: async (credentials: LoginSmsTypes) => {
      return axiosInstance
        .post(`/auth/login/sms`, credentials)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Logowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "loginSms",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Zalogowano pomyślnie",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "loginSms",
      });
      (async () => {
        await queryClient.prefetchQuery({ queryKey: ["me"] });
        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      })();
    },
    onError: (error: any, variables, context) => {
      if (error?.status === 500) {
        Toast({
          message: "Błąd serwera",
          type: TOAST_TYPES_ENUM.ERROR,
          id: "loginSms",
        });
      }
      if (error?.status === 422) {
        Toast({
          message: "Nieprawidłowy kod SMS",
          type: TOAST_TYPES_ENUM.ERROR,
          id: "loginSms",
        });
      }
      if (error?.status !== 422 && error?.status !== 500) {
        toast.dismiss("loginSms");
      }

      console.log(error);
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
